
:root {

  /*-----------------------------------*\
          VARIABLES
  \*-----------------------------------*/

      /*
          COLORS
      */

          /* gradient */

  --bg-gradient-onyx: linear-gradient(
    to bottom right,
    hsl(240, 1%, 25%) 3%,
    hsl(0, 0%, 19%) 97%
  );
  --bg-gradient-jet: linear-gradient(
      to bottom right,
      hsla(240, 1%, 18%, 0.251) 0%,
      hsla(240, 2%, 11%, 0) 100%
    ),
    hsl(240, 2%, 13%);
  --bg-gradient-yellow-1: linear-gradient(
    to bottom right,
    hsl(133, 100%, 71%) 0%,
    hsla(115, 100%, 69%, 0) 50%
  );
  --bg-gradient-yellow-2: linear-gradient(
      135deg,
      hsla(45, 100%, 71%, 0.251) 0%,
      hsla(35, 100%, 68%, 0) 59.86%
    ),
    hsl(240, 2%, 13%);
  --border-gradient-onyx: linear-gradient(
    to bottom right,
    hsl(0, 0%, 25%) 0%,
    hsla(0, 0%, 25%, 0) 50%
  );
  --text-gradient-green: linear-gradient(
    to right,
    hsl(45, 100%, 72%),
    hsla(101, 98%, 52%, 0.628)
  );
  --text-gradient-yellow: linear-gradient(
    to right,
    hsl(45, 100%, 72%),
    hsl(35, 100%, 68%)
  );

  background-color: #FFDEE9;

  --bg-gradient-2: linear-gradient(
    0deg, #FFDEE9 0%, #B5FFFC 100%
    );



    /*
        solid
    */

  --error: #fc0404;
  --blue-dark: #072C42;
  --blue-light: #095C8F;
  --brown-dark: #430102;
  --brown-light: #424107;
  --red: #8F1012;
  --gold: #FCA311;
  --jet: hsl(0, 0%, 22%);
  --onyx: hsl(240, 1%, 17%);
  --black-eerie: hsl(240, 2%, 12%);
  --black-eerie-2: rgba(30, 30, 31, 0.75);
  --smoky-black: hsl(0, 0%, 7%);
  --white: hsl(0, 0%, 100%);
  --green: hsl(125, 100%, 72%);
  --light-gray: hsl(0, 0%, 84%);
  --light-gray-70: hsla(0, 0%, 84%, 0.7);
  --bittersweet-shimmer: hsl(0, 43%, 51%);



    /*
        TYPOGRAPHY
    */


/*
    font-family
*/

  --ff-poppins: "Poppins", sans-serif;


/*
    font-size
*/

  --fs-1: 24px;
  --fs-2: 18px;
  --fs-3: 17px;
  --fs-4: 16px;
  --fs-5: 15px;
  --fs-6: 14px;
  --fs-7: 13px;
  --fs-8: 11px;


/*
    font-weight
*/

  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;



    /*
        SHADOW
    */

  --shadow-1: -7px 7px 4px rgba(0, 0, 0, 0.5);
  --shadow-2: -5px 5px 4px rgba(0, 0, 0, 0.5);


    /*
        transition
    */

--transition-1: 0.25s ease;
--transition-2: 0.5s ease-in-out;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

body {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-image: url(https://images.unsplash.com/photo-1508389377389-b8221c0bcc9e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80);
  background-position: top top;
  background-size: cover;
  margin: 0;
}


.container {
  width: 100%;
  margin: 15% auto;
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}

    /*
        ELEMENTS
    */

        /*
            Navbar
        */

.navbar-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}


    /*
        CLASSES
    */


.logo {
  position: relative;
}

.logo img {
  position: absolute; /* Position the logo absolutely within the container */
  top: 0; /* Set top to 0 to position it at the top */
  left: 0; /* Set left to 0 to position it at the left */
  /* Adjust width and height for your logo size */
  width: 4rem;
  height: 3.3rem;
  z-index: 2; /* Set z-index to ensure it displays on top of other elements */
}

.swiper {
  width: 100vw;
  height: 100vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.slide-content {
  position: relative; /* Enable relative positioning for child elements */
  width: 100%; /* Set width to 100% for full width */
  height: 100%; /* Set a desired height for the container */
}

.slide-content-img {
  position: absolute; /* Make image absolute for full positioning */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Set image to fill the entire container */
  object-fit: cover; /* Ensure image covers the entire area */
}

.slide-content-body {
  position: absolute; /* Make text absolute for positioning */
  top: 50%; /* Center text vertically */
  left: 50%; /* Center text horizontally */
  transform: translate(-50%, -50%); /* Offset text to center */
  color: white; /* Set text color for visibility on image */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Semi-transparent background */
  padding: 20px; /* Add some padding for better readability */
  width: 80%;
}


.nav-link.active {
  font-weight: bold;
  color: #007bff;
}